import { Environment } from 'relay-runtime';

import { HasAccessRequest } from '@attentive/acore-utils';
import { Permission, Role } from '@attentive/data';

export type DataBundle<FeatureFlags extends string = string> = {
  relayEnvironment: Environment;
  getCompanyFeatureFlag: (featureFlag: FeatureFlags) => boolean;
  getCurrentCompanyId: () => string;
  getPermission: (permission: Permission) => boolean;
  getRoles: () => Set<Role>;
  getHasAccessTo: (request: HasAccessRequest) => boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let dataBundle: DataBundle<any> | null = null;

function getDataBundle(): DataBundle {
  if (!dataBundle) {
    throw new Error('Missing data bundle.');
  }

  return dataBundle;
}

function setDataBundle<FeatureFlags extends string>(bundle: DataBundle<FeatureFlags>) {
  dataBundle = bundle;
}

export { getDataBundle, setDataBundle };
